<template>
    <div class="orders">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h4 class="box-title">Notas </h4>
                </div>
                <div class="col" v-if="settings_obj.ver_boleta">
                  <a href="#" title="Click para solicitar notas"
                  @click.prevent="get_settings()">
                  <span class="d-none d-sm-block">Solicitar notas</span>
                  <span class="d-block d-sm-none">Ver notas</span>
                </a>
                </div>
                <div class="col text-right" v-if="settings_obj.ver_boleta">
                  <a href="#" @click="printPDF" title="Exportar en formato pdf">
                    <i class="fa fa-file-pdf-o fa-2x text-danger "></i></a>
                </div>
              </div>
            </div>
            <div class="card-body" v-if="settings_obj.ver_boleta" >
              <div class="d-none d-sm-block table-stats order-table ov-h">
                <table class="table ">
                  <thead>
                    <tr>
                      <th class="serial">#</th>
                      <th>Curso</th>
                      <th v-for="(b, key) in bimestre_obj" :key="key"> {{b.name}} </th>
                      <th class="serial">% </th>
                      <th class="serial">Aprobado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(entry, index) in list_data" is="tr" :key="index">
                      <td class="serial">{{ index + 1 }}</td>
                      <td>{{entry.course_name}}</td>
                      <td :class="note_bimestre.class_color"
                        v-for ="(note_bimestre, key) in entry.note_bimester_obj" :key="key">
                        {{note_bimestre.note}}
                      </td>
                      <td class="serial" v-if="entry.note_percent">{{entry.note_percent}} </td>
                      <td class="serial" v-else>0 </td>
                      <td class="serial">
                        <span v-if="entry.approved" class="badge badge-complete">
                          {{entry.approved|status_to_text}}
                        </span>
                        <span v-else class="badge badge-pending">
                          {{entry.approved|status_to_text}} </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
              <div class="d-block d-sm-none table-stats order-table ov-h">
                <table
                 class="table" v-for="(entry, index) in list_data" is="tr" :key="index">
                  <thead>
                    <th>Bimestre</th>
                    <th>Nota</th>
                  </thead>
                  <tbody >
                    <tr >
                      <th style="width: 10%"
                      colspan="2" class="text-center">{{entry.course_name}}</th>
                    </tr>
                    <tr :class="note_bimestre.class_color"
                        v-for ="(note_bimestre, k) in entry.note_bimester_obj" :key="k">
                      <td>{{bimestre_obj[k].name}}</td>
                      <td>{{note_bimestre.note}}</td>
                    </tr>
                    <tr>
                      <td>% Promedio </td>
                      <td v-if="entry.note_percent">{{entry.note_percent}} </td>
                      <td v-else>0 </td>
                    </tr>
                     <tr>
                      <td>Para aprobar</td>
                      <td v-if="entry.note">{{entry.note - settings_obj.promedio_aprobar}} </td>
                      <td v-if="!entry.note">0 </td>
                    </tr>
                     <tr >
                      <td>Aprobado</td>
                      <td v-if="entry.note">
                        <span v-if="entry.approved" class="badge badge-complete">
                          {{entry.approved|status_to_text}}
                        </span>
                        <span v-else class="badge badge-pending">
                          {{entry.approved|status_to_text}} </span>
                      </td>
                      <td v-if="!entry.note">
                        ----
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> <!-- /.table-stats -->
            </div>
            <div class="card-body" v-if="!settings_obj.ver_boleta">
              <div class="d-sm-block ov-h">
                <h2>NO ES POSIBLE VER NOTAS</h2>
              </div>
            </div>

          </div> <!-- /.card -->
        </div>  <!-- /.col-lg-8 -->
        </div>
    </div>
</template>
<script>
import moment from 'moment';

const jsPDF = require('jspdf');
require('jspdf-autotable');
// const moment = require('moment');

/* eslint-disable */
export default {
  name: 'Notas',
  created(){
    this.get_settings();
    this.get_bimestres();
    if(this.settings_obj.ver_boleta) {
      // this.list();
    }
  },
  data() {
    return {
      logo_url: '',
      base: process.env.VUE_APP_BASE_URL,
      numeros: [ 0, 1, 2, 3 ],
      list_data: [],
      bimestre_obj: [],
      url: 'courses/'
    };
  },
  methods: {
    save_storage(key, data) {
      localStorage.setItem([key], JSON.stringify(data));
    },
    handlerError: (error) => {
      const result = error.response.data;
      const self = this;
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(([key, value]) => { window.$swal.fire('Error!', `${key}: ${value}`, 'error'); });
        return false;
      }
      self.$swal.fire('Error!', result, 'error');
      return false;
    },
    get_settings() {
      const payload = {};
      const self = this;
      self.sendRequest(payload, 'settings/').then((data) => {
        const settings = data.data;
        self.$store.dispatch('set_data_settgins', { settings });
        self.save_storage('settings', settings);
        if(settings.ver_boleta) {
          this.list()
        }
      }).catch((error) => {
        console.log(error)
        if (!error.response) {
          return false;
        }
        this.handlerError(error);
        return true;
      });
    },
    printPDF() {
      let doc = new jsPDF.jsPDF('p', 'pt');
      let totalPagesExp = '{total_pages_count_string}'
      let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc.autoTable({ html: '.table', 
        useCss: false,
        theme: 'striped',
        styles: {          
          cellPadding: 5,
          fontSize: 8,          
        },
        margin: {top: 120},
        didDrawPage: (data) => {
          doc.setFontSize(12)
          doc.text('INSTITUTO NACIONAL DE EDUCACIÓN BÁSICA "INEB"', pageWidth / 2, 25, 'center');
          doc.text('NOTAS BIMESTRALES',pageWidth / 2, 40, 'center');
          doc.text('CICLO ESCOLAR ' + this.settings_obj.ciclo_name, pageWidth / 2, 55, 'center');
          doc.text('Alumno (a): ' + this.$store.getters.student_name , 40, 70);
          doc.text( this.settings_obj.grade_name + ' Sección: "' + this.settings_obj.section_name  +'"', 40, 85);
          doc.setFontSize(9)
          doc.setTextColor(9, 223, 25) // green
          doc.text('Nivel esperado:', 250, 84);
          doc.setTextColor(0, 0, 0) // white
          doc.text('Se desarrolla adecuadamente. De 80-100.', 312, 84);
          doc.setFillColor(9,223,25);
          doc.setDrawColor(255,255,255);
          doc.circle(240, 82, 5, 'FD');

          doc.setTextColor(244, 237, 0) // yellow
          doc.text('En desarrollo:', 250, 97);
          doc.setTextColor(0, 0, 0) // white
          doc.text('Requiere de más esfuerzo. De 60-79.', 306, 97);
          doc.setFillColor(244,237,0);
          doc.setDrawColor(255,255,255);
          doc.circle(240, 95, 5, 'FD');

          doc.setTextColor(255, 0, 0) // Red
          doc.text('Requiere de apoyo:', 250, 110);
          doc.setTextColor(0, 0, 0) // white
          doc.text('Necesita más acompañamiento y dedicación. De 0-59.', 330, 110);                  
          doc.setFillColor(255,0,0);
          doc.setDrawColor(255,255,255);
          doc.circle(240, 108, 5, 'FD');
                    
          // Footer
          var str = 'Página ' + doc.internal.getNumberOfPages()
          // Total page number plugin only available in jspdf v1.0+          
          if (typeof doc.putTotalPages === 'function') {
            str = str + ' de ' + totalPagesExp
          }
          doc.setFontSize(10)

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          doc.text(str, pageWidth / 2, pageHeight - 10)
          doc.text('Fecha impresión: ' + moment().locale('es').format('LLL'), data.settings.margin.left, pageHeight - 10)
        },
        willDrawCell: (data) => {
          if (data.cell.text[0] < 60 && data.column.dataKey > 1 && data.column.dataKey < 6) {
            doc.setTextColor(231, 76, 60) // Red
            doc.setFont(undefined, 'bold')
          }
        },
      })

      

      doc.setFontSize(12)
      if (this.settings_obj.logo_url){
        doc.addImage(this.settings_obj.logo_url, 'JPEG',  60, 10, 50, 50, 'monkeys')
      }
      if (this.settings_obj.avatar_url){
        doc.addImage(this.settings_obj.avatar_url, 'JPEG', pageWidth - 100 , 10, 50, 50, 'avatar')
      }
      if (this.settings_obj.stamp_url){
        doc.addImage(this.settings_obj.stamp_url, 'JPEG', 290 , pageHeight - 105, 90, 60, 'firma')
      }      
      doc.text('Licda. Olga Gisela Gutiérrez León', 300, pageHeight -50,'center');      
      doc.text('DIRECTORA', 300, pageHeight -35,'center');
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp)
      }
      
      var blob = doc.output("blob");
      window.open(URL.createObjectURL(blob));
      //doc.save('NOTAS.pdf')
    },
    handlerError: (error) => {
      const result = error.response.data
      let self = this
      if (typeof (error.response.data) === 'object') {
        Object.entries(result).forEach(
          ([key, value]) => self.$swal.fire('Error!', key + ': ' + value, 'error')
        )
        return false
      }
      self.$swal.fire('Error!', result, 'error')
    },
    get_bimestres() {
      const payload = { recovered : 'False'};
      payload.ciclo = this.$store.state.settings_obj.ciclo
      const self = this;
      this.sendRequest(payload, 'bimestres/').then((data) => {
        self.bimestre_obj = data.data;
      }).catch((error) => {
        this.handlerError(error)
      });
      return null;
    },
    list() {
      const payload = {};
      payload.ciclo = this.$store.state.settings_obj.ciclo
      // payload.basic_serializer = 'True' // remporal remover para ver boleta. 
      const self = this;
      this.sendRequest(payload, this.url).then((data) => {
        self.list_data = data.data;            
      }).catch((error) => {
        console.log(error);
        self.$swal.fire('Error!', 'No fue posible obtener los registros.', 'error');
      });
      return null;
    },
    async sendRequest(payload, url) {
      const result = await this.$http.get(url,{params:  payload});
      // const result = await this.$http.get(url, payload);
      return result;
    },
  },
  filters: {
    status_to_text(value){
      if (value) return 'Sí';
      return 'No';
    }
  },
  computed: {
    settings_obj() {
      return this.$store.state.settings_obj;
    },
  },
};

</script>
<style lang="css" scoped>
 .success {
    font-weight: 500;
    background-color: #4aad09;
  }
  .warning {
    font-weight: 500;
    background-color: yellow;
  }
  .danger {
    font-weight: 500;
    background-color: red;
  }
  table {
    font-size: 2em;
  }
</style>